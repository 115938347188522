import { z } from 'zod';

export const emailSchema = z
  .string({
    message: 'Adres e-mail jest wymagany',
  })
  .email({ message: 'Błędny adres e-mail' });

export const loginFormSchema = z.object({
  email: emailSchema,
  password: z.string({ message: 'Hasło jest wymagane' }),
  remember: z.boolean().optional(),
});

export const loginCodeFormSchema = z.object({
  email: emailSchema,
  code: z.string({ message: 'Podaj kod weryfikacyjny' }).length(6, {
    message: 'Kod weryfikacyjny musi mieć 6 znaków',
  }),
});

export const sendTokenPasswordFormSchema = z.object({
  email: emailSchema,
});

export const tokensDataSchema = z.object({
  token: z.string(),
  refresh_token: z.string(),
  token_expires_at: z.string(),
});

export const resetPasswordSchema = z
  .object({
    password: z.string({ message: 'Hasło jest wymagane' }).min(8, {
      message: 'Hasło musi mieć co najmniej 8 znaków',
    }),
    password_confirmation: z.string({
      message: 'Potwierdzenie hasła jest wymagane',
    }),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Hasła różnią się od siebie',
    path: ['password_confirmation'],
  });

export const userSchema = z.object({
  name: z.string(),
  last_name: z.string().nullable(),
  email: emailSchema,
  has_accepted_terms: z.boolean().nullable(),
  id: z.number(),
  roles: z.array(
    z.object({
      name: z.string(),
    }),
  ),
});

export const schematsStatusSchema = z.union([
  z.literal('active'),
  z.literal('draft'),
  z.literal('template'),
]);

export const schematSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: schematsStatusSchema.nullable(),
});

export const schematsSchema = z.array(schematSchema);

export const detailedSchematSchema = schematSchema.and(
  z.object({
    screening_names: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        schemat_details: z.array(
          z.object({
            id: z.number(),
            week: z.union([z.number(), z.string()]).nullable(),
            study_day: z.union([z.number(), z.string()]).nullable(),
            visit_window: z.union([z.number(), z.string()]).nullable(),
            visit_name: z.string().nullable(),
            amount: z.union([z.number(), z.string()]).nullable(),
            currency: z.enum(['pln', 'eur', 'usd']).nullable(),
          }),
        ),
      }),
    ),
  }),
);

export const schematFormSchema = z.object({
  name: z
    .string({
      message: 'Nazwa jest wymagana',
    })
    .min(1, {
      message: 'Nazwa jest wymagana',
    }),
  schemat_screening_names: z
    .array(
      z.object({
        name: z
          .string({ message: 'Nazwa etapu jest wymagana' })
          .min(1, { message: 'Nazwa etapu jest wymagana' }),
        schemat_details: z.array(
          z.object({
            week: z.number({ message: 'Numer tygodnia jest wymagany' }),
            visit_name: z
              .string({ message: 'Nazwa wizyty jest wymagana' })
              .min(1, { message: 'Nazwa wizyty jest wymagana' }),
            study_day: z.number({
              message: 'Dzień badania jest wymagany',
            }),
            visit_window: z.number({
              message: 'Okno wizyty jest wymagane',
            }),
            amount: z.number({
              message: 'Kwota jest wymagana',
            }),
            currency: z.enum(['pln', 'eur', 'usd'], {
              message: 'Waluta jest wymagana',
            }),
          }),
        ),
      }),
    )
    .min(1, { message: 'Etap jest wymagany' }),
});

export const weekSchema = z.object({
  id: z.number().optional(),
  week: z.union([z.number(), z.string()]).nullable(),
  study_day: z.union([z.number(), z.string()]).nullable(),
  visit_window: z.union([z.number(), z.string()]).nullable(),
  visit_name: z.string({ message: 'Nazwa wizyty jest wymagana' }).nullable(),
  amount: z.union([z.number(), z.string()]).nullable(),
  currency: z.enum(['pln', 'eur', 'usd']).nullable(),
});

export const screeningSchema = z.object({
  id: z.number().optional(),
  name: z.string({ message: 'Nazwa wizyty jest wymagana' }),
  schemat_details: z.array(weekSchema),
});

export const addSchematFormSchema = z.object({
  name: z.string({ message: 'Nazwa schematu jest wymagana' }),
  schemat_screening_names: z.array(screeningSchema),
  status: schematsStatusSchema,
});

export const storeScreeningResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const surveyFormSchema = z.object({
  name: z.string({ message: 'Nazwa badania jest wymagana' }).min(1, {
    message: 'Nazwa badania jest wymagana',
  }),
  description: z.string({ message: 'Opis badania jest wymagany' }).min(1, {
    message: 'Opis badania jest wymagany',
  }),
  medic_id: z.number({ message: 'Główny badacz jest wymagany' }),
  coordinator_id: z.number({ message: 'Główna koordynator jest wymagany' }),
  schemat_ids: z
    .array(z.number(), {
      message: 'Schemat jest wymagany',
    })
    .min(1, {
      message: 'Schemat jest wymagany',
    }),
  medic_ids: z.array(z.number()),
  nurse_ids: z.array(z.number()),
  coordinator_ids: z.array(z.number()),
  duration: z
    .string({
      message: 'Czas trwania badania jest wymagany',
    })
    .min(1, {
      message: 'Czas trwania badania jest wymagany',
    }),
  survey_identification_number: z.string().nullable(),
});

export const personelSchema = z.object({
  email: emailSchema,
  name: z.string(),
  last_name: z.string().nullable(),
  roles: z
    .array(
      z.object({
        name: z.string(),
      }),
    )
    .nullable(),
  id: z.number(),
});

export const personelsSchema = z.array(personelSchema);

export const surveySchema = z.object({
  name: z.string(),
  description: z.string().nullable(),
  medic: personelSchema.nullable(),
  coordinator: personelSchema.nullable(),
  coordinators: z.array(personelSchema),
  medics: z.array(personelSchema),
  nurses: z.array(personelSchema),
  id: z.number(),
  schemats: schematsSchema,
  slug: z.string().optional(),
  duration: z.string(),
  survey_identification_number: z.string().nullable(),
});

export const surveysSchema = z.array(surveySchema);

export const medicineSchema = z.object({
  id: z.number(),
  name: z.string(),
  dose: z.number().nullable(),
  unit: z.string().nullable(),
  surveys: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    )
    .nullable(),
  name_active_substance: z.string().nullable(),
  pharmaceutical_form: z.string().nullable(),
  route_of_administration: z.string().nullable(),
});

export const medicinesSchema = z.array(medicineSchema);

export const medicineFormSchema = z.object({
  name: z.string({ message: 'Nazwa leku jest wymagana' }),
  dose: z.number({ message: 'Dawka jest wymagana' }),
  unit: z.string({ message: 'Jednostka jest wymagana' }),
  survey_ids: z.array(z.number()).nullable(),
  name_active_substance: z.string({
    message: 'Nazwa substancji czynnej jest wymagana',
  }),
  pharmaceutical_form: z.string({
    message: 'Postać farmaceutyczna jest wymagana',
  }),
  route_of_administration: z.string({ message: 'Droga podania jest wymagana' }),
});

export const formQuestionAnswerSchema = z.object({
  id: z.number(),
  answer: z.string(),
  order: z.number(),
  is_required_contact: z.boolean().nullable(),
});

export const formFormQuestionAnswerSchema = z.object({
  answer: z.string().optional(),
  order: z.number(),
  is_required_contact: z.boolean().nullable().optional(),
});

export const formQuestionSchema = z.object({
  id: z.number(),
  order: z.number(),
  is_required: z.boolean(),
  form_question_answers: z.array(formQuestionAnswerSchema),
  type: z.string(),
  question: z.string(),
});

export const formFormQuestionSchema = z.object({
  order: z.number(),
  is_required: z.boolean(),
  type: z.string(),
  question: z.string({ message: 'Tytuł pytania jest wymagany' }),
  form_question_answers: z.array(formFormQuestionAnswerSchema),
});

export const formTemplateQuestionSchema = z.object({
  id: z.number(),
  order: z.number(),
  is_required: z.boolean(),
  form_question_answer_templates: z.array(formQuestionAnswerSchema),
  type: z.string(),
  question: z.string(),
});

export const formTemplateSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  designation: z.string().nullable(),
  start_date: z.string().nullable(),
  end_date: z.string().nullable(),
  is_multiple_answer: z.boolean().nullable(),
  form_question_templates: z.array(formTemplateQuestionSchema),
  is_recurring: z.boolean().nullable(),
  recurring_days_of_week: z.array(z.number()).nullable(),
  recurring_interval: z.number().nullable(),
  recurring_type: z.string().nullable(),
  schemat_id: z.number().nullable(),
  type: z.string(),
});

export const formTemplatesSchema = z.array(formTemplateSchema);

export const formTemplatesMetaSchema = z.object({
  data: z.array(formTemplateSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const formSchema = z.object({
  id: z.number(),
  name: z.string(),
  total_form_user_results_count: z.number().nullable(),
  description: z.string().nullable(),
  designation: z.string().nullable(),
  start_date: z.string().nullable(),
  start_time: z.string().nullable(),
  end_date: z.string().nullable(),
  form_template_id: z.number().nullable(),
  is_active: z.boolean(),
  is_multiple_answer: z.boolean().nullable(),
  form_questions: z.array(formQuestionSchema),
  form_users: z.array(
    z.object({
      id: z.number(),
      user: userSchema,
      is_disabled: z.boolean().nullable(),
      created_at: z.string().nullable(),
      form_user_results: z.array(
        z.object({
          id: z.number(),
          result: z.record(z.string(), z.array(z.string())),
        }),
      ),
    }),
  ),
  is_recurring: z.boolean().nullable(),
  medicine: z.object({ id: z.number() }).nullable(),
  recurring_days_of_week: z.array(z.number()).nullable(),
  recurring_interval: z.number().nullable(),
  recurring_type: z.string().nullable(),
  schemat_id: z.number().nullable(),
  survey_id: z.number().nullable(),
  medicine_id: z.number().nullable(),
  send_for: z.string().nullable(),
  send_user_ids: z.array(z.number()).nullable(),
  type: z.string(),
});

export const formsSchema = z.array(formSchema);

export const formMetaSchema = z.object({
  data: z.array(formSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const formFormSchema = z
  .object({
    name: z.string().nullable().optional(),
    designation: z.string().nullable().optional(),
    form_template_id: z.number().nullable().optional(),
    description: z.string().nullable().optional(),
    is_active: z.boolean().nullable().optional(),
    form_questions: z.array(formFormQuestionSchema).nullable().optional(),
    start_date: z.date().nullable().optional(),
    start_time: z.date().nullable().optional(),
    end_date: z.date().nullable().optional(),
    is_recurring: z.boolean().nullable().optional(),
    recurring_type: z.string().nullable().optional(),
    recurring_interval: z.number().nullable().optional(),
    recurring_days_of_week: z.array(z.number()).nullable().optional(),
    is_multiple_answer: z.boolean().nullable().optional(),
    type: z.string().nullable().optional(),
    send_for: z.string().nullable().optional(),
    medicine_id: z.number().nullable().optional(),
    schemat_id: z.number().nullable().optional(),
    send_user_ids: z.array(z.number()).nullable().optional(),
    survey_id: z.number().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.designation) {
      ctx.addIssue({
        path: ['designation'],
        message: 'Oznaczenie jest wymagane',
        code: 'custom',
      });
    }
    if (!data.name) {
      ctx.addIssue({
        path: ['name'],
        message: 'Nazwa jest wymagana',
        code: 'custom',
      });
    }
    if (!data.form_questions || data.form_questions.length === 0) {
      ctx.addIssue({
        path: ['form_questions'],
        message: 'Wymagane jest choć jedno pytanie',
        code: 'custom',
      });
    } else {
      for (let i = 0; i < data.form_questions.length; i++) {
        if (!data.form_questions[i].question) {
          ctx.addIssue({
            path: ['form_questions'],
            message: 'Tytuł pytania jest wymagany',
            code: 'custom',
          });
        }
        if (
          !data.form_questions[i]?.form_question_answers?.length &&
          data.form_questions[i].type !== 'date'
        ) {
          ctx.addIssue({
            path: ['form_questions'],
            message: 'Proszę podać choć jedną odpowiedź',
            code: 'custom',
          });
        } else if (data.form_questions[i].type !== 'date') {
          for (
            let z = 0;
            z < data.form_questions[i].form_question_answers.length;
            z++
          ) {
            if (!data.form_questions[i].form_question_answers[z].answer) {
              ctx.addIssue({
                path: ['form_questions'],
                message: 'Odpowiedź na pytanie musi być wprowadzona',
                code: 'custom',
              });
            }
          }
        }
      }
    }

    if (['own', 'well_being'].includes(String(data.type))) {
      if (!data.start_date) {
        ctx.addIssue({
          path: ['start_date'],
          message: 'Data rozpoczęcia jest wymagana',
          code: 'custom',
        });
      }
      if (!data.start_time) {
        ctx.addIssue({
          path: ['start_time'],
          message: 'Godzina rozpoczęcia jest wymagana',
          code: 'custom',
        });
      }
      if (!data.end_date) {
        ctx.addIssue({
          path: ['end_date'],
          message: 'Data zakończenia jest wymagana',
          code: 'custom',
        });
      }
      if (
        data.recurring_type === 'choice_day' &&
        (!data.recurring_days_of_week ||
          data.recurring_days_of_week.length === 0)
      ) {
        ctx.addIssue({
          path: ['recurring_days_of_week'],
          message: 'Wymagany jest choć jeden dzień tygodnia',
          code: 'custom',
        });
      }
      if (
        data.send_for === 'users' &&
        (!data.send_user_ids || data.send_user_ids.length === 0)
      ) {
        ctx.addIssue({
          path: ['send_user_ids'],
          message: 'Pacjenci są wymagani',
          code: 'custom',
        });
      }
      if (data.send_for === 'all' && !data.schemat_id) {
        ctx.addIssue({
          path: ['schemat_id'],
          message: 'Schemat jest wymagany',
          code: 'custom',
        });
      }
    } else if (data.type === 'medicine') {
      if (!data.medicine_id) {
        ctx.addIssue({
          path: ['medicine_id'],
          message: 'Wymagany jest wybór leku',
          code: 'custom',
        });
      }
      if (!data.schemat_id) {
        ctx.addIssue({
          path: ['schemat_id'],
          message: 'Schemat jest wymagany',
          code: 'custom',
        });
      }
    } else if (data.type === 'adverse_event') {
      if (!data.survey_id) {
        ctx.addIssue({
          path: ['survey_id'],
          message: 'Badanie jest wymagane',
          code: 'custom',
        });
      }
    }
  });

export const personelMetaSchema = z.object({
  data: z.array(personelSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const workScheduleFormSchema = z.object({
  user_id: z.number(),
  dates: z.array(
    z.object({
      date: z.string(),
      start_time: z.string(),
      end_time: z.string(),
    }),
  ),
});

export const defaultNotificationSchema = z
  .object({
    title: z.string().optional(),
    designation: z.string().optional(),
    body: z.string().optional(),
    type: z.array(z.enum(['email', 'sms', 'push'])).optional(),
    is_recurring: z.boolean().optional(),
    recurring_type: z
      .enum(['choice_day', 'quantity_day', 'calendar_days'])
      .nullable()
      .optional(),
    recurring_interval: z.number().nullable().optional(),
    recurring_days_of_week: z.array(z.number()).nullable().optional(),
    recurring_calendar_days: z.array(z.string()).nullable().optional(),
    send_for: z.enum(['users', 'all']).optional(),
    send_user_ids: z.array(z.number()).optional(),
    schemat_id: z.number().nullable().optional(),
    end_date: z.date().nullable().optional(),
    start_date: z.date().nullable().optional(),
    start_time: z.date().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.designation) {
      ctx.addIssue({
        code: 'custom',
        path: ['designation'],
        message: 'Nazwa powiadomienia jest wymagana',
      });
    }

    if (!data.body) {
      ctx.addIssue({
        code: 'custom',
        path: ['body'],
        message: 'Treść powiadomienia jest wymagana',
      });
    }

    if (
      !Array.isArray(data.type) ||
      data.type.length === 0 ||
      !data.type.every((t) => ['email', 'sms', 'push'].includes(t))
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['type'],
        message: 'Typ powiadomienia jest wymagany',
      });
    }

    if (data.is_recurring !== true && data.is_recurring !== false) {
      ctx.addIssue({
        code: 'custom',
        path: ['is_recurring'],
        message: 'Rodzaj powiadomienia jest wymagany',
      });
    }

    if (data.is_recurring && !data.recurring_type) {
      ctx.addIssue({
        code: 'custom',
        path: ['recurring_type'],
        message: 'Rodzaj powtarzania jest wymagany',
      });
    }

    if (data.recurring_type === 'quantity_day' && !data.recurring_interval) {
      ctx.addIssue({
        code: 'custom',
        path: ['recurring_interval'],
        message: 'Ilość dni jest wymagana',
      });
    }

    if (
      data.recurring_type === 'choice_day' &&
      !data.recurring_days_of_week?.length
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['recurring_days_of_week'],
        message: 'Dni tygodnia są wymagane',
      });
    }

    if (
      data.recurring_type === 'calendar_days' &&
      !data.recurring_calendar_days?.length
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['recurring_calendar_days'],
        message: 'Dni kalendarzowe są wymagane',
      });
    }

    if (!data.send_for) {
      ctx.addIssue({
        code: 'custom',
        path: ['send_for'],
        message: 'Odbiorcy są wymagani',
      });
    }

    if (data.send_for === 'users' && !data.send_user_ids?.length) {
      ctx.addIssue({
        code: 'custom',
        path: ['send_user_ids'],
        message: 'Odbiorcy są wymagani',
      });
    }

    if (data.send_for === 'all' && !data.schemat_id) {
      ctx.addIssue({
        code: 'custom',
        path: ['schemat_id'],
        message: 'Schemat jest wymagany',
      });
    }

    if (data.recurring_type !== 'calendar_days' && !data.start_date) {
      ctx.addIssue({
        code: 'custom',
        path: ['start_date'],
        message: 'Data rozpoczęcia jest wymagana',
      });
    }

    if (
      data.is_recurring &&
      data.recurring_type !== 'calendar_days' &&
      !data.end_date
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['end_date'],
        message: 'Data zakończenia jest wymagana',
      });
    }

    if (!data.start_time) {
      ctx.addIssue({
        code: 'custom',
        path: ['start_time'],
        message: 'Godzina powiadomienia jest wymagana',
      });
    }
  });

export const formtNotificationSchema = z.object({
  title: z
    .string({
      message: 'Tytuł powiadomienia jest wymagany',
    })
    .min(1, {
      message: 'Tytuł powiadomienia jest wymagany',
    }),
  designation: z
    .string({
      message: 'Nazwa powiadomienia jest wymagana',
    })
    .min(1, {
      message: 'Nazwa powiadomienia jest wymagana',
    }),
  body: z
    .string({
      message: 'Treść powiadomienia jest wymagana',
    })
    .min(1, {
      message: 'Treść powiadomienia jest wymagana',
    }),
  type: z.array(z.enum(['email', 'sms', 'push']), {
    message: 'Typ powiadomienia jest wymagany',
  }),
  form_id: z.number({
    message: 'Formularz jest wymagany',
  }),
});

export const visitNotificationSchema = z.object({
  title: z
    .string({
      message: 'Tytuł powiadomienia jest wymagany',
    })
    .min(1, {
      message: 'Tytuł powiadomienia jest wymagany',
    }),
  designation: z
    .string({
      message: 'Nazwa powiadomienia jest wymagana',
    })
    .min(1, {
      message: 'Nazwa powiadomienia jest wymagana',
    }),
  body: z
    .string({
      message: 'Treść powiadomienia jest wymagana',
    })
    .min(1, {
      message: 'Treść powiadomienia jest wymagana',
    }),
  type: z.array(z.enum(['email', 'sms', 'push']), {
    message: 'Typ powiadomienia jest wymagany',
  }),
  schemat_id: z.number({
    message: 'Schemat badania jest wymagany',
  }),
  quantity_days: z.number({
    message: 'Ilość dni jest wymagana',
  }),
});

export const notificationScheduleStatusSchema = z.enum([
  'unprocessed',
  'success',
  'failed',
]);

export const notificationScheduleSchema = z.object({
  id: z.number(),
  user: z.object({
    name: z.string(),
    last_name: z.string().nullable(),
    email: z.string(),
  }),
  status: notificationScheduleStatusSchema,
});

export const notificationTemplateSchema = z.object({
  id: z.number(),
  schemat_id: z.number().nullable(),
  body: z.string(),
  title: z.string(),
  designation: z.string(),
  is_recurring: z.boolean().nullable(),
  kind_type: z.enum(['default', 'form', 'visit']),
  recurring_calendar_days: z.array(z.string()).nullable(),
  recurring_days_of_week: z.array(z.number()).nullable(),
  recurring_interval: z.number().nullable(),
  recurring_type: z
    .enum(['choice_day', 'quantity_day', 'calendar_days'])
    .nullable(),
  start_date: z.string().nullable(),
  start_time: z.string().nullable(),
  type: z.array(z.enum(['email', 'sms', 'push'])),
  form_id: z.number().nullable(),
  send_for: z.enum(['users', 'all']).nullable(),
  send_user_ids: z.array(z.number()).nullable(),
  end_date: z.string().nullable(),
  quantity_days: z.number().nullable(),
});

export const notificationCampaignSchema = z.object({
  ...notificationTemplateSchema.shape,
  notification_schedules: z.array(notificationScheduleSchema),
  schemat: z
    .object({
      name: z.string(),
    })
    .nullable(),
});

export const metaNotificationCampaignSchema = z.object({
  data: z.array(notificationCampaignSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const metaTemplateNotificationCampaignSchema = z.object({
  data: z.array(notificationTemplateSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const notificationTemplateFormSchema = z.object({
  template_id: z.number({
    message: 'Szablon powiadomienia jest wymagany',
  }),
});

export const patientVisitCostsSchema = z.object({
  name: z.string({ message: 'Nazwa kosztu jest wymagana' }).min(3, {
    message: 'Nazwa kosztu jest wymagana',
  }),
  amount: z.union([z.number(), z.string()], { message: 'Kwota jest wymagana' }),
  currency: z.enum(['pln', 'eur', 'usd'], {
    message: 'Waluta jest wymagana',
  }),
  id: z.number().nullable(),
});

export const visitCostFormSchema = z.object({
  costs: z.array(patientVisitCostsSchema),
});

export const visitFormSchema = z.object({
  patient_id: z.number({ message: 'Pacjent jest wymagany' }),
  survey_type_id: z.number().nullable().optional(),
  medic_ids: z.array(z.number()),
  title: z.string().optional(),
  description: z.string().optional(),
  info: z.string().optional(),
  equipment: z.string().optional(),
  activity: z.string().optional(),
  notes: z.string().optional(),
  date: z.string().nullable().optional(),
  medicine_ids: z.array(z.number()),
  nurse_ids: z.array(z.number()),
  visit_start_time: z.string().nullable().optional(),
  visit_end_time: z.string().nullable().optional(),
  files: z.array(z.any()),
  patient_visit_costs: z.array(patientVisitCostsSchema),
  amount: z.union([z.string(), z.number()]).optional(),
  currency: z.enum(['pln', 'eur', 'usd']).optional(),
});

export const visitStatusSchema = z.union([
  z.literal('to_plan'),
  z.literal('planned'),
  z.literal('not_confirmed'),
  z.literal('confirmed'),
  z.literal('rejected'),
  z.literal('completed'),
]);

export const visitSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  info: z.string().nullable(),
  equipment: z.string().nullable(),
  activity: z.string().nullable(),
  notes: z.string().nullable(),
  date: z.string().nullable(),
  visit_start_time: z.string().nullable(),
  visit_end_time: z.string().nullable(),
  medicines: z.array(
    z.object({
      id: z.number(),
    }),
  ),
  medics: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      last_name: z.string().nullable(),
    }),
  ),
  nurses: z.array(
    z.object({
      id: z.number(),
    }),
  ),
  // survey_type: z
  //   .object({
  //     id: z.number(),
  //     name: z.string(),
  //   })
  //   .nullable(),
  patient: z.object({
    id: z.number(),
    name: z.string(),
    last_name: z.string().nullable(),
  }),
  status: visitStatusSchema.nullable(),
  files: z.array(z.any()).nullable(),
  patient_visit_costs: z.array(patientVisitCostsSchema),
  amount: z.union([z.string(), z.number()]).nullable(),
  currency: z.enum(['pln', 'eur', 'usd']).nullable(),
  patient_notes: z
    .array(
      z.object({
        id: z.number(),
        afternoon: z.union([z.boolean(), z.number()]).nullable(),
        morning: z.union([z.boolean(), z.number()]).nullable(),
        noon: z.union([z.boolean(), z.number()]).nullable(),
        visit_date: z.string(),
      }),
    )
    .nullable()
    .optional(),
});

export const visitsSchema = z.array(visitSchema);

export const patientUserFormSchema = z.object({
  id: z.number(),
  form: z.object({
    name: z.string(),
    created_at: z.string().nullable(),
    type: z.string(),
  }),
  user: userSchema,
  form_user_results: z.array(
    z.object({
      id: z.number(),
      result: z.record(z.string(), z.array(z.string())),
    }),
  ),
  created_at: z.string().nullable(),
  is_disabled: z.boolean().nullable(),
});

export const patientSchema = z.object({
  id: z.number(),
  name: z.string(),
  last_name: z.string().nullable(),
  email: z.string(),
  phone: z.string().nullable(),
  mrn: z.string(),
  patient_survey: z.array(
    z.object({
      survey_id: z.number(),
      schemat_id: z.number().nullable(),
      survey: z.object({
        id: z.number(),
        name: z.string(),
      }),
      schemat: z
        .object({
          id: z.number(),
          name: z.string(),
        })
        .nullable(),
    }),
  ),
  permissions: z.object({
    can_create: z.boolean(),
    can_edit: z.boolean(),
    can_delete: z.boolean(),
  }),
  consent_signed_date: z.string().nullable(),
  gender: z.enum(['male', 'female']).nullable(),
  birth_date: z.string(),
  form_users: z.array(patientUserFormSchema).nullable(),
  visits: z
    .array(
      visitSchema.omit({
        patient: true,
      }),
    )
    .nullable(),
  patient_identification_number: z.string().nullable(),
});

export const patientsSchema = z.array(patientSchema);

export const patientMetaSchema = z.object({
  data: z.array(patientSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const workScheduleSchema = z.object({
  id: z.number(),
  user: personelSchema,
  date: z.string(),
  start_time: z.string(),
  end_time: z.string(),
});

export const workSchedulesSchema = z.array(workScheduleSchema);

export const symptomSchema = z.object({
  id: z.number(),
  name: z.string({ message: 'Nazwa objawu jest wymagana' }),
  surveys: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      slug: z.string(),
    }),
  ),
});

export const symptomsSchema = z.array(symptomSchema);

export const symptomFormSchema = z.object({
  name: z
    .string({ message: 'Nazwa objawu jest wymagana' })
    .min(1, { message: 'Nazwa objawu jest wymagana' }),
  survey_ids: z.array(z.number()),
});

export const userWorkScheduleSchema = z.array(
  z.object({
    date: z.string(),
    end_time: z.string(),
    start_time: z.string(),
  }),
);

export const patientFormEditSchema = z.object({
  name: z
    .string({ message: 'Imię pacjenta jest wymagana' })
    .min(1, { message: 'Imię pacjenta jest wymagana' }),
  last_name: z
    .string({ message: 'Nazwisko pacjenta jest wymagana' })
    .min(1, { message: 'Nazwisko pacjenta jest wymagana' }),
  email: emailSchema,
  phone: z
    .string()
    .optional()
    .refine((val) => !val || /^\d{9}$/.test(val), {
      message: 'Niepoprawny numer telefonu',
    }),
  birth_date: z.string({ message: 'Data urodzenia jest wymagana' }),
  patient_identification_number: z.string().optional().nullable(),
  consent_signed_date: z.string({
    message: 'Data podpisania zgody jest wymagana',
  }),
  gender: z.enum(['male', 'female'], {
    message: 'Płeć jest wymagana',
  }),
});

export const patientFormSchema = z.object({
  ...patientFormEditSchema.shape,
  survey_id: z.number({
    message: 'Badanie jest wymagane',
  }),
  schemat_id: z.number({
    message: 'Schemat jest wymagany',
  }),
});

export const notificationSchema = z.object({
  id: z.number(),
  is_read: z.boolean(),
  title: z.string(),
  content: z.string(),
  url: z.string(),
  notification_type: z.string(),
  created_at: z.string(),
});

export const notificationsSchema = z.array(notificationSchema);

export const costReportSchema = z.object({
  id: z.number(),
  basic_amount: z.object({
    pln: z.number(),
    eur: z.number(),
    usd: z.number(),
  }),
  additional_amount: z.object({
    pln: z.number(),
    eur: z.number(),
    usd: z.number(),
  }),
  amount: z.object({
    pln: z.number(),
    eur: z.number(),
    usd: z.number(),
  }),
  created_at: z.string(),
  report: z.object({
    original_name: z.string(),
    url_download: z.string(),
  }),
});

export const costReportsSchema = z.object({
  data: z.array(costReportSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});

export const generateCostReportFormSchema = z
  .object({
    is_all_dates: z.boolean(),
    is_all_surveys: z.boolean(),
    user_ids: z.array(z.number()),
  })
  .and(
    z.discriminatedUnion('is_all_dates', [
      z.object({
        is_all_dates: z.literal(false),
        start_date: z.date({
          message: 'Data początkowa jest wymagana',
        }),
        end_date: z.date({
          message: 'Data końcowa jest wymagana',
        }),
      }),
      z.object({
        is_all_dates: z.literal(true),
        start_date: z.date().nullable().optional(),
        end_date: z.date().nullable().optional(),
      }),
    ]),
  )
  .and(
    z.discriminatedUnion('is_all_surveys', [
      z.object({
        is_all_surveys: z.literal(true),
        survey_ids: z.array(z.number()).optional(),
      }),
      z.object({
        is_all_surveys: z.literal(false),
        survey_ids: z
          .array(z.number(), {
            message: 'Wybierz przynajmniej jedno badanie',
          })
          .min(1, 'Wybierz przynajmniej jedno badanie'),
      }),
    ]),
  );

export const termsSchema = z.array(
  z.object({
    content: z.string(),
    term_version_id: z.number(),
  }),
);
export const storeFileResponse = z.object({
  id: z.number(),
  url: z.string(),
  token: z.string(),
  url_download: z.string(),
  mime_type: z.string(),
});

export const estimateReportEvent = z.discriminatedUnion('is_success', [
  z.object({
    is_success: z.literal(true),
    url: z.string(),
    filename: z.string(),
  }),
  z.object({
    is_success: z.literal(false),
    url: z.null(),
  }),
]);

export const guestTermsSchema = z.object({
  term_url: z.string().nullable(),
  policy_privacy_url: z.string().nullable(),
});

export const reportColumnsSchema = z.union([
  z.literal('clinic_name'),
  z.literal('clinic_number'),
  z.literal('clinic_city'),
  z.literal('main_medic_first_name'),
  z.literal('main_medic_last_name'),
  z.literal('patient_number'),
  z.literal('patient_status'),
  z.literal('patient_year_of_birth'),
  z.literal('patient_gender'),
  z.literal('date_sign_consent'),
  z.literal('date_last_visit'),
  z.literal('date_adverse_event'),
  z.literal('date_take_medicine'),
  z.literal('medicine_status'),
  z.literal('medicine_quantity'),
  z.literal('medicine_dose_daily'),
  z.literal('medicine_compliance'),
  z.literal('visit_name'),
  z.literal('visit_status'),
  z.literal('visit_date'),
  z.literal('visit_on_window'),
  z.literal('visit_compliance'),
  z.literal('date_exclusion_survey'),
  z.literal('survey_name'),
  z.literal('date_inclusion_survey'),
]);

export const reportFormSchema = z
  .object({
    is_all_dates: z.boolean(),
    is_all_surveys: z.boolean(),
    name: z.string({
      message: 'Nazwa raportu jest wymagana',
    }),
    columns: z
      .array(reportColumnsSchema, {
        message: 'Wybierz przynajmniej jedną kolumnę',
      })
      .min(1, {
        message: 'Wybierz przynajmniej jedną kolumnę',
      }),
    user_ids: z.array(z.number()),
  })
  .and(
    z.discriminatedUnion('is_all_dates', [
      z.object({
        is_all_dates: z.literal(false),
        start_date: z.date({
          message: 'Data początkowa jest wymagana',
        }),
        end_date: z.date({
          message: 'Data końcowa jest wymagana',
        }),
      }),
      z.object({
        is_all_dates: z.literal(true),
        start_date: z.date().nullable().optional(),
        end_date: z.date().nullable().optional(),
      }),
    ]),
  )
  .and(
    z.discriminatedUnion('is_all_surveys', [
      z.object({
        is_all_surveys: z.literal(true),
        survey_ids: z.array(z.number()).optional(),
      }),
      z.object({
        is_all_surveys: z.literal(false),
        survey_ids: z
          .array(z.number(), {
            message: 'Wybierz przynajmniej jedno badanie',
          })
          .min(1, 'Wybierz przynajmniej jedno badanie'),
      }),
    ]),
  );

export const reportSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  name: z.string(),
  report: z.object({
    url_download: z.string(),
    original_name: z.string(),
  }),
  surveys: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
});

export const reportsSchema = z.object({
  data: z.array(reportSchema),
  meta: z.object({
    current_page: z.number(),
    total: z.number(),
  }),
});
